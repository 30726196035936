import {
  CREATE_EVALUATION,
  RETRIEVE_EVALUATIONS,
  UPDATE_EVALUATION,
  DELETE_EVALUATION,
  DELETE_ALL_EVALUATIONS,
} from "./types";

import EvaluationDataService from "../services/EvaluationService";

export const createEvaluation = (query) => async (dispatch) => {
  try {
    const res = await EvaluationDataService.create({ query });

    dispatch({
      type: CREATE_EVALUATION,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveEvaluations = () => async (dispatch) => {
  try {
    const res = await EvaluationDataService.getAll();

    dispatch({
      type: RETRIEVE_EVALUATIONS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateEvaluation = (id, data) => async (dispatch) => {
  try {
    const res = await EvaluationDataService.update(id, data);

    dispatch({
      type: UPDATE_EVALUATION,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteEvaluation = (id) => async (dispatch) => {
  try {
    await EvaluationDataService.remove(id);

    dispatch({
      type: DELETE_EVALUATION,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllEvaluations = () => async (dispatch) => {
  try {
    const res = await EvaluationDataService.removeAll();

    dispatch({
      type: DELETE_ALL_EVALUATIONS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findEvaluationsByText = (text) => async (dispatch) => {
  try {
    const res = await EvaluationDataService.findByText(text);

    dispatch({
      type: RETRIEVE_EVALUATIONS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};