import "../../styles/logos/chartExample.scss";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import { createChart } from "../../actions/charts";

const ChartExample = () => {

  const initialChartState = {
    functionCode: "{x. *(x, x)}",
    startPoint: -10,
    endPoint: 10,
    stepSize: 0.5,
    chart: [],
  };
  
  const [input, setInput] = useState(initialChartState);
  const [chart, setChart] = useState(initialChartState);

  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInput({ ...input, [name]: value });
  };

  const saveChart = event => {
    event.preventDefault();
    const { functionCode, startPoint, endPoint, stepSize } = input;
    dispatch(createChart(functionCode, startPoint, endPoint, stepSize))
      .then((data) => {
        console.log(data);
        setChart(data);
      })
      .catch((e) => {
        console.log(e);
        setChart([]);
      });
  };

  const data = chart.length===0?[]:chart.chart.map((yValue, i) => {
    return (
      {x: chart.startPoint + i * chart.stepSize, y: yValue}
    );
  });

  return (
    <div className="chart-example">
      <form id="input-form" className="input-form" action="" onSubmit={saveChart}>
        <div className="chart-display">
          <LineChart width={800} height={400} data={data}>
            <Line type="linear" dataKey="y" stroke="black" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="x" />
            <YAxis />
          </LineChart>
          <div>
            <label htmlFor="start-point">Start point:</label><br />
            <input
              id="start-point"
              type="number"
              step="any"
              name="startPoint"
              value={input.startPoint}
              required
              onChange={handleInputChange}
            /><br />
            <label htmlFor="end-point">End point:</label><br />
            <input
              id="end-point"
              type="number"
              step="any"
              name="endPoint"
              value={input.endPoint}
              required
              onChange={handleInputChange}
            /><br />
            <label htmlFor="step-size">Step size:</label><br />
            <input
              id="step-size"
              type="number"
              step="any"
              name="stepSize"
              value={input.stepSize}
              required
              onChange={handleInputChange}
            />
          </div>
        </div>
        <input
            id="function-code"
            type="text"
            name="functionCode"
            value={input.functionCode}
            autoComplete="off"
            size="110"
            required
            onChange={handleInputChange}
          />
        <input type="submit" value="&crarr;" />
      </form>
    </div>
  );
};

export default ChartExample;