import '../../styles/logos/logos.scss'

import React from 'react';

import EvaluationExample from './EvaluationExample';
import ChartExample from './ChartExample';
import TradebotExample from './TradebotExample';

const Logos = () => {
  return (
    <div className="logos">
      <div className="description">
        <p>
          The following is a short introduction to an interpreted functional programming language I named Logos. I designed it and wrote its interpreter as a toy project to learn the fundamental principles of programming, both by doing the actual work and by studying the subject matter.
        </p>
        <p>
          The language is rather bare-bones at the moment and consequently has no pretensions of practical usefulness. It is basically an idiosyncratic implementation of lambda calculus. Like in lambda calculus, the interpreter takes an expression and evaluates it.
        </p>
        <p>
          Bellow, I present you with various examples of Logos expressions in the input fields to introduce you to the syntax. You can rewrite the expressions, hit the submit button, and see the steps involved in the computation leading to the final answer.
        </p>
        <p>
          <a href="https://github.com/ondrejtrisc/Logos" target="_blank">The interpreter</a> is written in Java.
        </p>
      </div>
      <EvaluationExample id={0} instruction="The simplest example is just an arithmetical expression. All expressions are in prefix notation, with the arguments arrayed in parentheses following the arithmetical operation." query="+(-(1, 2), *(+(3.4, 5, 6), -(7, 8), *(9.1, 2)), /(/(3, 4), 5))" />
      <EvaluationExample id={1} instruction="Besides the arithmetical operations, the language also supports the basic logical ones." query="||(&(true, false, true), !(!(true)), false, &(true, ||(false, !(false))))" />
      <EvaluationExample id={3} instruction="A single equals sign is used for comparison. There are also strict and inclusive greater and lesser than comparison operators." query="&(=(2, -(5, 3)), <(2, 3), >(2, 1), <=(5, 5), <=(0, 0))" />
      <EvaluationExample id={4} instruction="If statement is implemented as a function which takes its condition as its first parameter." query="if(=(2, 3), equal, unequal)" />
      <EvaluationExample id={5} instruction="User-defined functions are written inside curly braces. The head of the function consists of either a single parameter or of several parameters separated by commas. The head and the body are separated by a dot. The body is just an expression which can include the parameters from the head. To call a function, just follow it by its arguments in parentheses as with the elementary functions. The following is an example of a single-parameter function which converts Fahrenheit to Celsius." query="{fahrenheit. *(-(fahrenheit, 32), /(5, 9))}(451)" />
      <EvaluationExample id={6} instruction="Logos is a functional language, therefore functions (though only user-defined ones) can be passed as arguments to other functions." query="{f. f(2)}({x. +(x, 3)})" />
      <EvaluationExample id={7} instruction="Here is an example of what happens when a function which expects two parameters receives only a single argument. It returns a single-parameter function which expects the remaining argument." query="{x, y. +(x, y)}(2)" />
      <EvaluationExample id={8} instruction="The only data structure is a list, which is denoted by brackets." query="[+(2, 3), ||(true, false), abc]" />
      <EvaluationExample id={9} instruction="Lists can be used as arguments for the map function." query="map({x. +(2, x)}, [2, 3, 4, 5])" />
      <EvaluationExample id={10} instruction="The filter function is also implemented." query="filter({x. =(x, 2)}, [1, 2, 3])" />
      <EvaluationExample id={11} instruction="... as is the reduce function." query="reduce({x, y. +(x, y)}, [1, 2, 3, 4, 5], 0)" />
      <EvaluationExample id={12} instruction="Unfortunately, there is currently no way to directly write recursive functions, however recursive calculations can be performed using the help of the fixed-point combinator. The following is the code of a function which recursively calculates the factorial of the number 5." query="({f. {x. f(x(x))}({x. f(x(x))})}({factorial, n. if(=(n, 0), 1, *(n, factorial(-(n, 1))))}))(5)" />
      <div className="description">
        If you write the code of a Logos function from real numbers to real numbers, you can see its chart. You can specify the function's range and the distance between the points at which the value is calculated.
      </div>
      <ChartExample />
      <div className="description">
        As a bit of an experimental feature, Logos has a built in rudimentary support for writing tradebots (simulated tradebots, it doesn't actually connect to any trading platform). The code bellow is a bot which buys 100 shares of Apple (if it doesn't own any yet) if the price of a share drops bellow 120 dollars, and sells everything if the price rises above 180 dollars. If you hit the submit button, you can see the chart of the bot's profits if it had traded in the year 2021.
      </div>
      <TradebotExample />
    </div>
  );
};

export default Logos;