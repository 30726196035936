import "../../styles/logos/tradebotExample.scss";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import { createTradebot } from "../../actions/tradebots";

const TradebotExample = () => {

  const initialTradebotState = {
    id: null,
    code: "{tradebotInput. map({t. if(&(<(AAPL(t), 120), <(OWN(AAPL), 100)), [t, [[AAPL, -(100, OWN(AAPL))]]], if(>(AAPL(t), 180), [t, [[AAPL, -(0, OWN(AAPL))]]], [t, []]))}, tradebotInput)}",
    startPoint: 0,
    endPoint: 250,
    stepSize: 1,
  };
  
  const [tradebot, setTradebot] = useState(initialTradebotState);
  const [results, setResults] = useState([]);

  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTradebot({ ...tradebot, [name]: value });
  };

  const saveTradebot = event => {
    event.preventDefault();
    const { code } = tradebot;
    dispatch(createTradebot(code))
      .then((data) => {
        console.log(data);
        setResults(data.chart);
      })
      .catch((e) => {
        console.log(e);
        setResults([]);
      });
  };

  const data = results===[]?[]:results.map((yValue, i) => {
    return (
      {x: tradebot.startPoint + i * tradebot.stepSize, y: yValue}
    );
  });

  return (
    <div className="tradebot-example">
      <div className="results-display">
        <LineChart width={1000} height={400} data={data}>
          <Line type="linear" dataKey="y" stroke="black" />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="x" />
          <YAxis />
        </LineChart>
      </div>
      <form id="input-form" className="input-form" action="" onSubmit={saveTradebot}>
        <input
          id="code"
          type="text"
          name="code"
          value={tradebot.code}
          autoComplete="off"
          size="110"
          required
          onChange={handleInputChange}
        />
        <input type="submit" value="&crarr;" />
      </form>
    </div>
  );
};

export default TradebotExample;