import {
  CREATE_TRADEBOT,
  RETRIEVE_TRADEBOTS,
  UPDATE_TRADEBOT,
  DELETE_TRADEBOT,
  DELETE_ALL_TRADEBOTS,
} from "../actions/types";

const initialState = [];

function tradebotReducer(tradebots = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_TRADEBOT:
      return [...tradebots, payload];

    case RETRIEVE_TRADEBOTS:
      return payload;

    case UPDATE_TRADEBOT:
      return tradebots.map((tradebot) => {
        if (tradebot.id === payload.id) {
          return {
            ...tradebot,
            ...payload,
          };
        } else {
          return tradebot;
        }
      });

    case DELETE_TRADEBOT:
      return tradebots.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_TRADEBOTS:
      return [];

    default:
      return tradebots;
  }
};

export default tradebotReducer;