import '../../styles/logos/evaluationExpample.scss'

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createEvaluation } from "../../actions/evaluations";

const EvaluationExample = ({ id, instruction, query}) => {

  const initialEvaluationState = {
    id: null,
    query: query
  };
  
  const [evaluation, setEvaluation] = useState(initialEvaluationState);
  const [results, setResults] = useState([]);

  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEvaluation({ ...evaluation, [name]: value });
  };

  const saveEvaluation = event => {
    event.preventDefault();
    const { query } = evaluation;
    dispatch(createEvaluation(query))
      .then((data) => {
        console.log(data);
        setResults(data.evaluationList);
      })
      .catch((e) => {
        console.log(e);
        setResults(['error']);
      });
  };

  const resultsDisplay = 
    <ol key={'list-' + id}>
      {results.map((line, i) => <li key={'line-' + i}>{line}</li>)}
    </ol>;

  return (
    <div className="example">
      <div className="instruction">
        {instruction}
      </div>
      <div id={"console" + id}>
        <form id="input-form" className="input-form" action="" onSubmit={saveEvaluation}>
          <input
            id="query"
            type="text"
            name="query"
            value={evaluation.query}
            autoComplete="off"
            size="110"
            required
            onChange={handleInputChange}
          />
          <input type="submit" value="&crarr;" />
        </form>
      </div>
      <div id={"results-display" + id} className="results-display">
        {resultsDisplay}
      </div>
    </div>
  );
};

export default EvaluationExample;