import "../styles/home.scss";

import { Link } from "react-router-dom";
import React from "react";

const Home = () => {
  return (
    <>
      <main>
        <section id="home" className="home">
          <div className="home__padding"></div>
          <div className="greeting">
            <h1>
              My name is <span className="name">Ondřej Trišč.</span><br />
              I'm a software developer.
            </h1>
          </div>
          <div className="about">
            <p className="about__text">
            Having learnt the fundamentals of programming in my spare time while working as an English teacher, I made a decision to switch my career path and went through a web development boot camp. I worked for a year at an e-commerce startup as a junior web developer. Now, I'm looking for new challenges and opportunities. My professional experience is in PHP and JavaScript, but my language of choice for my personal projects is Java.
            </p>
          </div>
        </section>
        <section id="portfolio" className="portfolio">
          <div className="portfolio__padding"></div>
          <h2>SELECTED PERSONAL PROJECTS</h2>
          <div className="projects">
            <div id="java" className="project">
              <div>
                <Link to={"/logos"}>
                  <img src="img/logos.png" alt="logos" />
                </Link>
                <div className="project__description">
                  <Link to={"/logos"} className="nav-link">
                    <h3>Logos</h3>
                  </Link>
                  <p>
                    A Turing complete interpreted functional programming language.
                  </p>
                  <p>
                    A toy project I worked on to learn the fundamentals of programming.
                  </p>
                  <p className="line">
                    <span><em>Technologies:</em> Java</span>
                    <a href="https://github.com/ondrejtrisc/Logos" target="_blank">See the code</a>
                  </p>
                </div>
              </div>
            </div>
            <div id="life" className="project">
              <div>
                <Link to={"/life"} className="nav-link">
                  <img src="img/life.png" alt="life" />
                </Link>
                <div className="project__description">
                  <Link to={"/life"} className="nav-link">
                    <h3>Conway's Game of Life</h3>
                  </Link>
                  <p>
                    A cellular automaton running on a toroidal surface.
                  </p>
                  <p className="line">
                    <span><em>Technologies:</em> React</span>
                    <a href="https://github.com/ondrejtrisc/life" target="_blank">See the code</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio__padding"></div>
        </section>
        <section id="cv" className="cv">
          <h2>CV</h2>
          <div className="about">
            <img src="img/me.jpg" alt="Ondřej Trišč"/>
            <a href="/Ondřej Trišč CV.pdf" target="_blank" className="cv-link">Download my CV as pdf.</a>
          </div>
        </section>
        <section id="contact" className="contact">
          <h2>FIND ME HERE</h2>
          <div className="links">
            <a href="https://github.com/ondrejtrisc" target="_blank">
              <img src="img/github.png" alt="GitHub" className="github" />
              <span>GitHub: github.com/ondrejtrisc</span>
            </a>
            <a href="https://www.linkedin.com/in/ondrejtrisc" target="_blank">
              <img src="img/linkedin.png" alt="LinkedIn" className="linkedin" />
              <span>LinkedIn: linkedin.com/in/ondrejtrisc</span>
            </a>
          </div>
          <div className="email">
            email me at:<br />
            ondrejtrisc@gmail.com
          </div>
        </section>
        </main>
        <footer>
          Copyright @ Ondřej Trišč 2022
        </footer>
    </>
  );
};

export default Home;