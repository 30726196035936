export const CREATE_CHART = "CREATE_CHART";
export const RETRIEVE_CHARTS = "RETRIEVE_CHARTS";
export const UPDATE_CHART = "UPDATE_CHART";
export const DELETE_CHART = "DELETE_CHART";
export const DELETE_ALL_CHARTS = "DELETE_ALL_CHARTS";

export const CREATE_EVALUATION = "CREATE_EVALUATION";
export const RETRIEVE_EVALUATIONS = "RETRIEVE_EVALUATIONS";
export const UPDATE_EVALUATION = "UPDATE_EVALUATION";
export const DELETE_EVALUATION = "DELETE_EVALUATION";
export const DELETE_ALL_EVALUATIONS = "DELETE_ALL_EVALUATIONS";

export const CREATE_TRADEBOT = "CREATE_TRADEBOT";
export const RETRIEVE_TRADEBOTS = "RETRIEVE_TRADEBOTS";
export const UPDATE_TRADEBOT = "UPDATE_TRADEBOT";
export const DELETE_TRADEBOT = "DELETE_TRADEBOT";
export const DELETE_ALL_TRADEBOTS = "DELETE_ALL_TRADEBOTS";
