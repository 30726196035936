import http from "../http-common";

const getAll = () => {
  return http.get("/charts");
};

const get = (id) => {
  return http.get(`/charts/${id}`);
};

const create = (data) => {
  return http.post("/charts", data);
};

const update = (id, data) => {
  return http.put(`/charts/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/charts/${id}`);
};

const removeAll = () => {
  return http.delete("/charts");
};

const findByText = (text) => {
  return http.get(`/charts?text=${encodeURIComponent(text)}`);
};

const ChartService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByText
};

export default ChartService;