import "./styles/App.scss";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

import Home from "./components/Home";
import Board from "./components/life/Board";
import Logos from "./components/logos/Logos";

function App() {
  return (
    <Router>
      <nav className="nav-bar">
        <ul className="links">
          <li className="logo">
            <Link to={"/#home"} className="nav-link">
              <img src="/img/logo.png" alt="logo" />
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/#home"} className="nav-link">
              HOME
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/#portfolio"} className="nav-link">
              PROJECTS
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/#cv"} className="nav-link">
              CV
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/#contact"} className="nav-link">
              CONTACT
            </Link>
          </li>
        </ul>
      </nav>
      <Switch>
      <Route exact path="/" component={Home} />
        <Route exact path="/life" component={Board} />
        <Route exact path="/logos" component={Logos} />
      </Switch>
    </Router>
  );
}

export default App;
