import '../../styles/life/square.scss'

import React from 'react';

const Square = ({ color, changeColor, i, j }) => {
  return (
    <div className={"square " + color} onClick={() => changeColor(i, j)}>
    </div>
  );
};

export default Square;