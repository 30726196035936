import {
  CREATE_CHART,
  RETRIEVE_CHARTS,
  UPDATE_CHART,
  DELETE_CHART,
  DELETE_ALL_CHARTS,
} from "../actions/types";

const initialState = [];

function chartReducer(charts = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CHART:
      return [...charts, payload];

    case RETRIEVE_CHARTS:
      return payload;

    case UPDATE_CHART:
      return charts.map((chart) => {
        if (chart.id === payload.id) {
          return {
            ...chart,
            ...payload,
          };
        } else {
          return chart;
        }
      });

    case DELETE_CHART:
      return charts.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_CHARTS:
      return [];

    default:
      return charts;
  }
};

export default chartReducer;