import {
  CREATE_CHART,
  RETRIEVE_CHARTS,
  UPDATE_CHART,
  DELETE_CHART,
  DELETE_ALL_CHARTS,
} from "./types";

import ChartDataService from "../services/ChartService";

export const createChart = (functionCode, startPoint, endPoint, stepSize) => async (dispatch) => {
  try {
    const res = await ChartDataService.create({ functionCode, startPoint, endPoint, stepSize });

    dispatch({
      type: CREATE_CHART,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveCharts = () => async (dispatch) => {
  try {
    const res = await ChartDataService.getAll();

    dispatch({
      type: RETRIEVE_CHARTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateChart = (id, data) => async (dispatch) => {
  try {
    const res = await ChartDataService.update(id, data);

    dispatch({
      type: UPDATE_CHART,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteChart = (id) => async (dispatch) => {
  try {
    await ChartDataService.remove(id);

    dispatch({
      type: DELETE_CHART,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllCharts = () => async (dispatch) => {
  try {
    const res = await ChartDataService.removeAll();

    dispatch({
      type: DELETE_ALL_CHARTS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findChartsByText = (text) => async (dispatch) => {
  try {
    const res = await ChartDataService.findByText(text);

    dispatch({
      type: RETRIEVE_CHARTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};