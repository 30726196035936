import http from "../http-common";

const getAll = () => {
  return http.get("/evaluations");
};

const get = (id) => {
  return http.get(`/evaluations/${id}`);
};

const create = (data) => {
  return http.post("/evaluations", data);
};

const update = (id, data) => {
  return http.put(`/evaluations/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/evaluations/${id}`);
};

const removeAll = () => {
  return http.delete("/evaluations");
};

const findByText = (text) => {
  return http.get(`/evaluations?text=${encodeURIComponent(text)}`);
};

const EvaluationService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByText
};

export default EvaluationService;