import {
  CREATE_EVALUATION,
  RETRIEVE_EVALUATIONS,
  UPDATE_EVALUATION,
  DELETE_EVALUATION,
  DELETE_ALL_EVALUATIONS,
} from "../actions/types";

const initialState = [];

function evaluationReducer(evaluations = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_EVALUATION:
      return [...evaluations, payload];

    case RETRIEVE_EVALUATIONS:
      return payload;

    case UPDATE_EVALUATION:
      return evaluations.map((evaluation) => {
        if (evaluation.id === payload.id) {
          return {
            ...evaluation,
            ...payload,
          };
        } else {
          return evaluation;
        }
      });

    case DELETE_EVALUATION:
      return evaluations.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_EVALUATIONS:
      return [];

    default:
      return evaluations;
  }
};

export default evaluationReducer;